<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    attachModule="exameFisico"
  >
    <b-row>
      <b-col>
          <div class="form-group mb-0 break-spaces">
            <v-text-area
              id="exameFisico"
              :value="form.fields.exameFisico"
              @blur="updateMedicalRecord(form)"
              @inputTextArea="el => updateForm('exameFisico', el)"
              :readonly="!canEdit"
              rows="1"
              placeholder="Descrever"
            />
        </div>
      </b-col>
    </b-row>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import TextArea from '@/components/General/TextArea'

export default {
  components: {
    FormWrapper,
    'v-text-area': TextArea,
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.exameFisico,
    })
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/exameFisico', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.fields.exameFisico = null
    }
  }
}
</script>
